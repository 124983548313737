/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./luckyBox.vue?vue&type=template&id=220d9691&scoped=true"
import script from "./luckyBox.vue?vue&type=script&lang=js"
export * from "./luckyBox.vue?vue&type=script&lang=js"
import style0 from "./luckyBox.vue?vue&type=style&index=0&id=220d9691&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220d9691",
  null
  
)

export default component.exports