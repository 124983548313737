import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const depositVuex = createNamespacedHelpers("deposit");
const assetsVuex = createNamespacedHelpers("assets");
import { toNano } from "@ton/core";
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "depositDetail",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting", "tonConnect"]),
    ...userVuex.mapState(["userData", 'extraInvite']),
    ...depositVuex.mapState(["depositLog", "logHasMore", "logLoading", "logCondition"]),
    ...assetsVuex.mapState(["assetsChainsList", "assetsList", "chainsInfo", "assetsInfo"])
  },
  data() {
    return {
      moneyList: [0.01, 1, 3, 5, 10],
      depositAmount: 1,
      loadConfig,
      scorllFlag: false,
      depositAddress: "",
      isFlag: true
    };
  },
  beforeRouteLeave(to, from, next) {
    this.isFlag = true;
    window.removeEventListener('scroll', this.checkScroll);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "tonConnect": {
      handler(bool) {
        if (bool && this.assetsInfo.chain == 'ton') {
          this.withdrawAddress = this.tonWallet.uiAddress;
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    if (this.assetsChainsList.length == 0) await this.getAssetsChains();
    if (this.assetsList.length == 0) await this.getAssetsList();
    let cid = this.$route.query.cid || 0;
    let id = this.$route.query.id || 0;
    if (!cid || !id) {
      this.$router.back();
      return;
    }
    if (!this.chainsInfo || !this.chainsInfo.id) {
      this.assetsChainsList.map(item => {
        if (item.id == cid) {
          this.setChainsInfo(item);
        }
      });
    }
    if (!this.assetsInfo || !this.assetsInfo.id) {
      this.assetsList.map(item => {
        if (item.id == id) {
          this.setAssetsInfo(item);
        }
      });
    }

    // if(!this.chainsInfo.id || !this.assetsInfo.id) {
    //   this.$router.back();
    //   return;
    // }
    setTimeout(() => {
      this.onLoadData(1);
      this.withdrawAmount = this.assetsInfo.balance;
      window.addEventListener('scroll', this.checkScroll);
      console.log("1111", this.tonConnect, this.chainsInfo);
      if (this.tonConnect && this.assetsInfo.chain == 'ton') {
        this.withdrawAddress = this.tonWallet.uiAddress;
      }
    }, 500);
  },
  methods: {
    ...depositVuex.mapActions(["doDeposit", "getDepositLog", "getDepositLogStatus"]),
    ...depositVuex.mapMutations(["setLogCondition"]),
    ...userVuex.mapMutations(['setUserData']),
    ...assetsVuex.mapActions(["getAssetsInfo", "getAssetsChains", "getAssetsList"]),
    ...assetsVuex.mapMutations(["setChainsInfo", "setAssetsInfo"]),
    async confirmFn() {
      if (!this.isFlag) return;
      if (!this.depositAmount || this.depositAmount <= 0) {
        this.$toasted.error('Please enter deposit amount');
      }
      this.isFlag = true;
      this.$nextTick(async () => {
        if (!this.tonConnect) {
          this.isFlag = true;
          this.$nextTick(() => {
            let res = this.tonWallet.openOrCloseTonModal(true);
            this.tonWallet.ton.onStatusChange(async walletAndwalletInfo => {
              if (walletAndwalletInfo) {
                this.isFlag = false;
                this.depositAddress = this.tonWallet.uiAddress;
                this.onSubmitDeposit();
              }
            });
          });
        } else {
          this.depositAddress = this.tonWallet.uiAddress;
          this.onSubmitDeposit();
        }
      });
    },
    async onSubmitDeposit() {
      await this.doDeposit([this.assetsInfo.chain, this.assetsInfo.symbol, this.depositAmount, this.depositAddress, async res => {
        if (res && res.raw) {
          let msg = [{
            address: res.contract,
            amount: toNano(res.amount).toString(),
            payload: res.raw
          }];
          this.tonWallet.sendTransaction(msg);
        }
        await this.getAssetsInfo([this.assetsInfo.chain, this.assetsInfo.symbol]);
        this.onLoadData(1);
      }]);
    },
    checkScroll() {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (!this.scorllFlag) {
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          // 接近底部时触发加载  
          this.scorllFlag = true;
          if (this.logHasMore && !this.logLoading) {
            let page = this.logCondition.page;
            this.onLoadData(page + 1);
          }
        }
      }
    },
    async onLoadData(page) {
      this.setLogCondition({
        "chain": this.assetsInfo.chain,
        "symbol": this.assetsInfo.symbol,
        "limit": 10,
        "page": page
      });
      await this.getDepositLog([() => {
        this.scorllFlag = false;
      }, () => {
        this.scorllFlag = false;
      }]);
    },
    async checkOrderStatus(event, orderId) {
      const element = event.target;
      element.style.animation = 'refreshAmn 1s linear forwards';
      await this.getDepositLogStatus([orderId, "withdraw", async () => {
        await this.getAssetsInfo([this.assetsInfo.chain, this.assetsInfo.symbol]);
        this.withdrawAmount = this.assetsInfo.balance;
        element.style.animation = ''; // 移除动画属性  
      }, () => {
        element.style.animation = ''; // 移除动画属性  
      }]);
    },

    async refresh() {
      const element = this.$refs.refRefresh;
      element.style.animation = 'refreshAmn 1s linear forwards';
      await this.getAssetsInfo([this.assetsInfo.chain, this.assetsInfo.symbol]);
      await this.onLoadData(1);
      element.style.animation = ''; // 移除动画属性  
      document.documentElement.scrollTo({
        top: 0,
        left: 0
      });
    },
    amountInput(val) {
      this.withdrawAmount = val.target.value.replace(/(\.\d\d)\d*/, '$1');
    },
    openView(item) {
      this.WebApp.openLink(this.chainsInfo.tx_view_url + item.tx_hash);
    },
    onTonConnect() {
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
      });
    },
    doSelectMoney(index) {
      this.depositAmount = index;
    }
  }
};