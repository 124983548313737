import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import Vue from "vue";
export default {
  name: "bioMatrixScanDetail",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      result: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    FS.init({
      keyid: 'hp45b2137118875162',
      //key
      tgId: this.userData.tg_id,
      //tgid
      dev: false
    });
  },
  methods: {
    openIF() {
      FS.scan();
    },
    async checkSF() {
      let apikey = 'a5f18f7ee668e90720b893153306cad7';
      this.result = 'checking';
      this.result = await Vue.prototype.$http.post("https://face.humanpass.net/face/check", {
        apikey,
        "uid": this.userData.tg_id
      });
    },
    onClose() {
      this.WebApp.close();
    }
  }
};