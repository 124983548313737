/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./airdropSecond.vue?vue&type=template&id=82a9ffcc&scoped=true"
import script from "./airdropSecond.vue?vue&type=script&lang=js"
export * from "./airdropSecond.vue?vue&type=script&lang=js"
import style1 from "./airdropSecond.vue?vue&type=style&index=1&id=82a9ffcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82a9ffcc",
  null
  
)

export default component.exports